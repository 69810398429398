<template>
    <div>
        <section class="encabezado encuesta-medicina row-start">

            <h2>Encuesta</h2>

        </section>
        <section class="contenedor-encuesta row-between" id="encuesta">

            <h2>Dirigida a los alumni Ufv de farmacia</h2>

            <legend>Datos personales</legend>


            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="name" placeholder="Nombre" v-model="data.name"
                                :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="surnames" placeholder="Apellidos"
                                v-model="data.surnames" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="dni" placeholder="DNI" :class="classes"
                                v-model="data.dni">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|email" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="email" placeholder="Correo electrónico"
                                :class="classes" v-model="data.email">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <legend>Dirección postal</legend>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="address" placeholder="Dirección completa"
                                :class="classes" v-model="data.address">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="cp" placeholder="Código postal" :class="classes"
                                v-model="data.cp">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="city" placeholder="Población" :class="classes"
                                v-model="data.city">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input autocomplete="off" type="text" name="province" placeholder="Provincia"
                                :class="classes" v-model="data.province">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                    </div>


                    <legend>Datos profesionales</legend>

                    <div class="contenedor-input row-start fww">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }"
                            class="full-width row-start fww">
                            <input type="radio" value="0" name="working_status" id="no-mir"
                                v-model="data.working_status" :class="classes">
                            <label for="no-mir">En búsqueda activa de empleo</label>

                            <input type="radio" value="1" name="working_status" id="si" v-model="data.working_status"
                                :class="classes">
                            <label for="si">Trabajando</label>

                            <input type="radio" value="2" name="working_status" id="no-trabajando"
                                v-model="data.working_status" :class="classes">
                            <label for="no-trabajando">En formación</label>

                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <template v-if="data.working_status == '1'">

                        <legend>Datos de tu puesto</legend>

                        <div class="contenedor-input half">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="company" placeholder="Empresa"
                                    :class="classes" v-model="data.company">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>

                        </div>

                        <div class="contenedor-input half">
                            <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="text" name="position" placeholder="Cargo"
                                    :class="classes" v-model="data.position">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>

                        </div>



                    </template>


                    <div class="contenedor-input -check">
                        <ValidationProvider rules="required|min:3" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <input type="checkbox" id="id" :class="classes" v-model="data.politica" name="politica">
                            <label for="id">He leído y acepto la <a href="">Política de privacidad</a></label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <a @click="validate" class="boton-azul">Enviar</a>
                </form>

            </ValidationObserver>


            <div class="form-popup-encuesta" :show="!!error">
                <div class="popup" v-if="error">
                    <h2>{{errores[error].titulo}}</h2>
                    <p>{{errores[error].cuerpo}}</p>
                    <a @click="reload" class="boton-azul">Aceptar</a>
                </div>
            </div>

        </section>
    </div>
</template>


<script>

    import axios from 'axios';

    export default {
        name: 'encuesta-farmacia',
        data: () => ({
            data: {
                name: '',
                surnames: '',
                dni: '',
                email: '',
                address: '',
                cp: '',
                city: '',
                province: '',
                company: '',
                position: '',
                hospital_city: '',
                hospital_year: '',
                hospital_work: '',
                working_status: '',
                politica: false
            },
            errores: {
                'send': {
                    titulo: '¡Muchas gracias!',
                    cuerpo: "Hemos recibido tus datos"
                }
            },
            error: null,
            clicked: false
        }),
        methods: {
            reset() {
                this.errors.clear();
                this.clicked = false;
                this.data = {
                    name: '',
                    surnames: '',
                    dni: '',
                    email: '',
                    address: '',
                    cp: '',
                    city: '',
                    province: '',
                    company: '',
                    position: '',
                    hospital_city: '',
                    hospital_year: '',
                    hospital_work: '',
                    working_status: '',
                    politica: false
                }
            },
            reload() {
                location.reload();
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        axios.post('https://cuestionarios.alumni-ufv.es/api/contacto/farmacia', this.data).then(response => {
                            if (response.data.status) {
                                this.error = 'send';
                                this.reset();
                            }
                        })

                    }
                });
            },
        }
    }
</script>